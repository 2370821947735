.fancybox-container:not(.fancybox-controls--canzoomIn) .fancybox-placeholder img {
	image-rendering: optimizeSpeed;             /*                     */
	image-rendering: -moz-crisp-edges;          /* Firefox             */
	image-rendering: -o-crisp-edges;            /* Opera               */
	image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
	image-rendering: pixelated;                 /* Chrome as of 2019   */
	image-rendering: optimize-contrast;         /* CSS3 Proposed       */
	-ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
}
.fancybox-caption {border-top: none; padding: 10px 0 20px;}
.fancybox-caption-wrap {pointer-events: auto; opacity: 1; }
@media(max-width: 556px) {
	.fancybox-caption-wrap {padding: 20px 10px 0;}
}
.fancybox-slider-wrap {bottom: 30px;}
.fancybox-caption .fa {font-size: 22px;}
.fancybox-caption .form-control-range {-webkit-appearance: none; appearance: none; width: 100%; height: 10px; background: #fff; outline: none; opacity: 0.7; border-radius: 5px; -webkit-transition: opacity .15s ease-in-out; transition: opacity .15s ease-in-out; }
.fancybox-caption .form-control-range:hover {opacity: 1;}
.fancybox-caption .form-control-range::-webkit-slider-thumb {-webkit-appearance: none; appearance: none; border:none; width: 20px; height: 20px; background: #007bff; cursor: pointer; border-radius: 50%;}
.fancybox-caption .form-control-range::-moz-range-thumb {width: 20px; height: 20px; border:none; background: #007bff; cursor: pointer; border-radius: 50%;}
.fancybox-caption .range {display: flex; flex-direction: column; justify-content: center;}
.fancybox-caption label {margin-bottom: 0;}
.fancybox-caption__body {overflow: hidden;}

/* Type Ahead */
.tt-query {
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
	color: #999
}
.tt-menu {
	width: 422px;
	margin-top: 4px;
	padding: 4px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
	-moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
	box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
.tt-suggestion {
	padding: 3px 20px;
	line-height: 24px;
}
.tt-suggestion.tt-cursor,.tt-suggestion:hover {
	color: #fb642d;
	background-color: #fb642d;
}
